<template>
	<div class="container_settlement">
		<div class="header-box">
			<span class="ret" @click="ret">付款管理/</span> <span>付款详情</span>
		</div>
		<div class="header-one">
			<div class="rask-title" v-if="taskDetail">
				<div class="rt-left">
					<div class="rt-left-title">{{ taskDetail.title }}</div>
					<div class="rt-left-time">{{ taskDetail.taskCreateTime }}发布
						<span style="color: blue;font-weight: blod;">（
							<span v-if="taskDetail.payStatus === '10'">待审核</span>
							<span v-if="taskDetail.payStatus === '20'">审核成功</span>
							<span v-if="taskDetail.payStatus === '21'">付款处理中</span>
							<span v-if="taskDetail.payStatus === '30'">支付成功</span>
							<span v-if="taskDetail.payStatus === '40'">待提交</span>
							<span v-if="taskDetail.payStatus === '50'">未达到支付条件</span>
							<span v-if="taskDetail.payStatus === '60'">取消支付</span>
							）
						</span>
					</div>

				</div>
				<div class="rt-right" v-if="roleInfo === '102001'">
					<a-button type="primary" :loading="loadingFail"
						@click="visibleExamineModal = true">补传任务成果</a-button>
					<a-button type="danger" v-if="taskDetail.payStatus === '10' || taskDetail.payStatus === '20'" :loading="loadingFail"
						@click="shFail" style="margin-left: 10px">取消</a-button>
					<a-button type="primary" v-if="taskDetail.payStatus === '10'" :loading="loading" @click="Sh"
						style="margin-left: 10px">复核</a-button>

					<!-- <a-button
            style="margin-left: 12px"
            type="primary"
            :loading="loading"
            v-if="taskDetail.payStatus === '10'"
            @click="Sh"
            >审核</a-button
          > -->
					<a-button style="margin-left: 12px" type="primary" :loading="loading" v-if="taskDetail.payStatus === '20'"
						@click="Fk">付款</a-button>
				</div>
			</div>
			<div class="rask-content" v-if="taskDetail">
				<a-row :gutter="24">
					<a-col :span="8" class="rc-mgb">付款批次号：{{ taskDetail.orderBatchId }}</a-col>
					<a-col :span="8" class="rc-mgb">付款单号：{{ taskDetail.id }}</a-col>
					<!-- <a-col :span="8" class="rc-mgb" v-if="taskDetail.payType==='10'">付款类型：预付款</a-col>
          <a-col :span="8" class="rc-mgb" v-else>付款类型：尾款</a-col> -->
					<a-col :span="8" class="rc-mgb">付款人数：{{ taskDetail.payNum }}</a-col>
					<a-col :span="8" class="rc-mgb" v-if="taskDetail.payType === '10'">到账金额：{{
              taskDetail.payAmount === null
                ? "--"
                : taskDetail.payAmount.toFixed(2)
            }} 元</a-col>
					<a-col :span="8" class="rc-mgb" v-else-if="taskDetail.payType === '20'">到账金额：{{
              taskDetail.payAmount === null
                ? "--"
                : taskDetail.payAmount.toFixed(2)
            }}元</a-col>
					<a-col :span="8" class="rc-mgb" v-else>到账金额：{{
              taskDetail.payAmount === null
                ? "--"
                : taskDetail.payAmount.toFixed(2)
            }}元</a-col>
					<a-col :span="8" class="rc-mgb">费用金额：{{
              taskDetail.payFeeAmount === null
                ? "--"
                : taskDetail.payFeeAmount.toFixed(2)
            }}元</a-col>
					<a-col :span="8" class="rc-mgb">付款金额：{{
              taskDetail.payTotalAmount === null
                ? "--"
                : taskDetail.payTotalAmount.toFixed(2)
            }}元</a-col>

				</a-row>
				<a-row :gutter="24">
					<a-col :span="3" class="rc-mgb" style="font-weight: bold;color: blue;">任务成果：</a-col>
					<a-col :span="16" class="rc-mgb">
							<a-row
							  :value="item.id"
							  v-for="(item, index) in detailResultList"
							  :key="index"
								style="margin-top: 5px;"
							>
							  <a :href="item.fileUrl" target="_blank" :key="index">{{ item.fileName }}</a>
							</a-row>
						
					</a-col>
				</a-row>
			</div>
		</div>

		<div class="search">
			<span>姓名：</span>
			<a-input style="width: 200px" v-model="params.payeeName" placeholder="请输入姓名" />
			<span>风控状态：</span>
			<a-select
			  v-model="params.riskStatus"
			  placeholder="请选择风控状态"
			  style="width: 190px"
			>
			  <a-select-option
			    :value="item.id"
			    v-for="(item, index) in riskStatusArr"
			    :key="index"
			  >
			    {{ item.name }}
			  </a-select-option>
			</a-select>
			<div class="table-search-submitButtons">
				<a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
				<a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
			</div>
		</div>
		<div class="content_tab">
			<s-table :style="{
          borderTop: '1px solid #f0f0f0',
          marginTop: '16px',
          maxWidth: tableWidth,
        }" ref="setTable" :columns="columns" :data="datas" :rowKey="(record) => record.id" :scroll="{ x: true }">
				<span slot="userName" slot-scope="text, record">
					<div>{{ record.payeeName }}</div>
					<div>{{ record.payeePhoneNo }}</div>
					<div>{{ record.payeeIdcardNo }}</div>
				</span>
				<span slot="bankInfo" slot-scope="text, record">
					<div>{{ record.payeeBankName }}</div>
					<div>{{ record.payeeBankNo }}</div>
				</span>
				<span slot="riskStatu" slot-scope="text">
					<div v-if="text == '103301'">还未开始处理</div>
					<div v-else-if="text == '103310'" style="color: red">
						已被风控
					</div>
					<div v-else-if="text == '103321'">未被风控</div>
					<div v-else>--</div>
				</span>
				<span slot="BDAmount" slot-scope="text, record">
					<!-- <span style="border-bottom:1px solid #222;cursor:pointer;" @click="BDDetail(record)">{{text}}</span> -->
					<span style="border-bottom: 1px solid #222; cursor: pointer"
						@click="BDDetail(record)">{{ text ? text.toFixed(2) : "0.00" }}</span>
				</span>
				<span slot="action" slot-scope="text,record">
					<a @click="handleVoucherByDetail(text)">电子回单</a>
				</span>
			</s-table>
		</div>
		<!-- 付款信息 -->
		<a-modal title="付款信息" :visible="visibleFk" :footer="null" @cancel="handleCancelFk" width="600px">
			<ul class="fkxx" v-if="accountInfo">
				<li class="li_flex">
					<span class="left" v-if="taskDetail.payWalletType == '1'">银行卡余额</span>
					<span class="left" v-if="taskDetail.payWalletType == '2'">支付宝余额</span>
					<div class="right">
						<span>{{ accountInfo.normalBalanceAmount }}元</span>
						<a-button type="link" @click="showHkxx">请转入</a-button>
					</div>
				</li>
				<li class="li_flex">
					<span class="left">到账金额</span>
					<div class="right">
						<div>
							<span style="color: #fa541c; font-size: 24px">{{
                taskDetail.payTotalAmount.toFixed(2)
              }}</span>
							<span>元</span>
						</div>
					</div>
				</li>

				<!-- 交易密码 -->
				<li v-if="configValue == '100401'" class="li_flex">
					<span class="left">交易密码</span>
					<div class="right">
						<a-input v-model="payPwd" type="password" style="width: 220px" placeholder="请输入交易密码"></a-input>
						<a-button type="link" @click="resetMm">重置密码</a-button>
					</div>
				</li>
				<!-- 验证码 -->
				<li v-if="configValue == '100402'">
					<div class="bind_phone">
						<span>{{ enterpirseBindPhone }}</span>
						<a-button type="link" @click="getVerifyCode">{{
              codeName
            }}</a-button>
					</div>
					<div class="li_flex">
						<span class="left">验证码</span>
						<div class="right">
							<a-input v-model="verifyCode" style="width: 220px" placeholder="请输入验证码"></a-input>
						</div>
					</div>
				</li>

				<!-- 交易密码 -->
				<li v-if="configValue == '100403'" class="li_flex">
					<span class="left">交易密码</span>
					<div class="right">
						<a-input v-model="payPwd" type="password" style="width: 220px" placeholder="请输入交易密码"></a-input>
						<a-button type="link" @click="resetMm">重置密码</a-button>
					</div>
				</li>
				<!-- 验证码 -->
				<li v-if="configValue == '100403'">
					<div class="bind_phone">
						<span>{{ enterpirseBindPhone }}</span>
						<a-button type="link" @click="getVerifyCode">{{
              codeName
            }}</a-button>
					</div>
					<div class="li_flex">
						<span class="left">验证码</span>
						<div class="right">
							<a-input v-model="verifyCode" style="width: 220px" placeholder="请输入验证码"></a-input>
						</div>
					</div>
				</li>

				<li class="li_flex">
					<span class="left"></span>
					<div class="right">
						<a-button type="primary" @click="ljfk" :loading="btnLoading">立即付款</a-button>
					</div>
				</li>
			</ul>
		</a-modal>
		<!-- 付款信息 -->
		<a-modal title="汇款信息" :visible="visiblehk" @ok="handleOkhk" @cancel="handleCancelhk" width="700px">
			<div>
				<div class="hkxx-header">
					<a-icon theme="filled" style="color: #1890ff; margin-right: 4px"
						type="info-circle" />温馨提示：请线下汇款，汇款时间在工作日9：00-16：30之间，预计2小时内到账，请注意查收到账短
					信或邮件。
				</div>
				<ul class="hkxx-content">
					<li>
						<span class="left">付款账户</span>
						<span class="right">{{ payEnterpriseName || "" }}</span>
					</li>
					<li>
						<span class="left">收款账户名</span>
						<span class="right">{{ bankInfo.accountName }}</span>
					</li>
					<li>
						<span class="left">开户银行</span>
						<span class="right">{{ bankInfo.bankFullName }}</span>
					</li>
					<li>
						<span class="left">银行卡号</span>
						<span class="right">{{ bankInfo.bankNo }}</span>
					</li>
				</ul>
			</div>
		</a-modal>

		<!-- 申诉 -->
		<!-- <a-modal
      title="申诉"
      :visible="appealModal"
      @ok="appealModal = false"
      cancelText = ""
      width="700px"
    >
      <div>
        申诉
      </div>
    </a-modal> -->

		<!-- 补单 -->
		<a-modal title="补单详情" :visible="BDModal" :footer="null" width="800px" @cancel="BDModal = false" destroyOnClose>
			<div class="BD_detail">
				<s-table style="border-top: 1px solid #f0f0f0; margin-top: 16px" ref="bdTable" :columns="columnsBD"
					:data="DBDatas" :rowKey="(record) => record.id">
					<span slot="BDTitle">补缴费率
						<a-tooltip>
							<template slot="title">
								补缴金额：当前自由职业者服务费率发生改变，本月已完成的付款单需按照最新费率补缴服务费。
							</template>
							<a-icon type="info-circle" style="padding-left: 10px" />
						</a-tooltip>
					</span>
					<span slot="BDFee" slot-scope="text">{{ (text * 100).toFixed(2) }}%</span>
				</s-table>
			</div>
		</a-modal>

		<!-- 支付宝转入 -->
		<a-modal title="支付宝转入" :visible="visibleAlipay" @ok="alipayRecharge" @cancel="visibleAlipay = false" width="800px"
			destroyOnClose okText="转入">
			<div>
				<div class="img_tips">
					<div>
						<img src="../../assets/tips_icon.png" alt="注意" />
					</div>
					<div>
						<div style="padding-bottom:5px,color:#595959">
							点击“转入”按钮后会跳转到支付宝页面，请使用
							<span style="font-weight: bold">{{ enterpriseRealName }}</span>
							企业支付宝账户进行登录充值，
						</div>
						<div style="color: '#595959'">
							付款成功后请勿重复转入，如遇到问题请联系我们或拨打400-996-2055
						</div>
					</div>
				</div>
				<div class="alipay_recharge">
					<span style="color: #262626">转入金额：</span><a-input placeholder="请输入转入金额" type="number"
						v-model="alipayRechargeIpt" style="width: 300px"></a-input>
				</div>
			</div>
		</a-modal>
		<!-- 电子回单 -->
		<a-modal title="电子回单下载" :visible="voucherModel" @ok="voucherModel = false"
		 @cancel="voucherModel = false" width="500px">
			<a target="_blank" v-for="o in detailVoucherList" :href="o">{{o}}</a>
		</a-modal>
		<!-- 补传任务成果 -->
		<a-modal
		  title="补传任务结果"
		  width="600px"
		  destroyOnClose
		  :visible="visibleExamineModal"
		  @ok="handleUploadModalOk"
		  @cancel="visibleExamineModal = false"
		>
		  <a-form :form="examinForm">
		    <a-row class="form-row">
		      <a-col :lg="24" :md="24" :sm="24">
		        <a-form-item
		          label="任务结果"
		          :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
		          :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
		        >
		          <a-upload
		            :action="url.frontUrl"
		            :multiple="true"
		            :headers="headers"
		            :file-list="fileList"
		            :data="uploadData"
		            :beforeUpload="beforeUpload"
		            @change="handleChange"
		          >
		            <a-button> <a-icon type="upload" /> 上传 </a-button>
		          </a-upload>
		        </a-form-item>
		      </a-col>
		      <a-col :lg="24" :md="24" :sm="24">
		        <a-form-item
		          label="备注"
		          :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
		          :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
		        >
		          <a-textarea
		            placeholder="请填写备注"
		            v-decorator="[
		              'content',
		              {
		                rules: [
		                  {
		                    required: false,
		                    message: '请填写备注',
		                    whitespace: true,
		                  },
		                ],
		              },
		            ]"
		          ></a-textarea>
		        </a-form-item>
		      </a-col>
		    </a-row>
		  </a-form>
		</a-modal>
	</div>
</template>
<script>
	import STable from "@/components/table/";
	import { ACCESS_TOKEN } from "@/store/mutation-types";
	import Vue from "vue";
	import {
		taskWorkerPayItem,
		getPayDetails,
		taskWorkerPay,
		getBalance,
		prePay,
		taskPay,
		getUserBindEmail,
		resetPwd,
		getEnterpriseTradeDetaileds,
		getRoleType,
		getBanknum,
		queryEnterpriseTradeSupplementDetaileds,
		getSupplementAmount,
		getEnterprisePayConfig,
		getEnterprisePayCheckMode,
		getPayChenkCode,
		getEnterpriseBindPhone,
		alipayRecharge,
	} from "@/api/api";
	import {getRiskDetailList,getVoucherByDetail,getPayResultByPayId,resultSupplementUpload} from "@/api/payment"
	export default {
		components: {
			STable,
		},
		computed: {
			tableWidth() {
				return `${window.innerWidth - 396}px`;
			},
		},
		data() {
			return {
				url: {
				  frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
				},
				uploadData: {
				  fileType: "103206",
				},
				headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
				fileList: [],
				examinForm: this.$form.createForm(this),
				visibleExamineModal: false,
				params: {
					payeeName: "",
					riskStatus:"",
				},
				columns: [{
						title: "姓名/手机号/身份证",
						dataIndex: "userName",
						scopedSlots: {
							customRender: "userName"
						},
						width: "200px",
						fixed: "left",
					},
					{
						title: "所属银行/银行卡号",
						dataIndex: "phoneNo",
						scopedSlots: {
							customRender: "bankInfo"
						},
					},
					{
						title: "到账金额",
						dataIndex: "incomeAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
						title: "企业费率",
						dataIndex: "feePoint",
						customRender: (text) =>
							text === null ? "--" : (text * 100).toFixed(2) + "%",
					},
					{
						title: "企业费用金额",
						dataIndex: "feeAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
					  title: '个人服务费(元)',
					  dataIndex: 'userFeeAmount',
					  customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
					  title: '个人服务费费率',
					  dataIndex: 'userFeePoint',
					  customRender: (text) => text === null ? "--" : (text * 100).toFixed(2) + "%",
					},
					{
						title: "付款金额",
						dataIndex: "totalAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
						title: "支付状态",
						dataIndex: "payStatus",
						customRender: (text) =>
							text === "101401" ?
							"未付款" :
							text === "101402" ?
							"付款中" :
							text === "101411" ?
							"成功" :
							text === "101421" ?
							"付款失败" :
							text === "101422" ?
							"董监高校验失败" :
							text === "101410" ?
							"银行处理中" :
							text === "101423" ?
							"付款失败:余额不足" :
							text === "101424" ?
							"提现失败已退回" :
							"--",
					},
					{
						title: "风控状态",
						dataIndex: "riskControlStatus",
						scopedSlots: {
							customRender: "riskStatu"
						},
					},
					{
						title: "风控原因",
						dataIndex: "riskControlErrorInfo",
						customRender: (text) => < span style = "color:red;" > {
							{
								text
							}
						} < /span>,
					},
					{
						title: "付款失败原因",
						dataIndex: "payErrorInfo",
					},
					{
						title: "备注",
						dataIndex: "remark",
					},
					{
						title: "创建时间",
						dataIndex: "createTime",
					},{
						title: "操作",
						dataIndex: "id",
						scopedSlots: {
							customRender: "action"
						},
						width: "100px",
						fixed: "right",
					},
				],
				columnsBD: [{
						title: "时间",
						dataIndex: "supplementTime",
						width: "100px",
					},
					{
						title: "付款单号",
						dataIndex: "supplementDetaildId",
						width: "100px",
					},
					{
						title: "到账金额",
						dataIndex: "payAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
						width: "100px",
					},
					{
						dataIndex: "supplementFeePoint",
						slots: {
							title: "BDTitle"
						},
						scopedSlots: {
							customRender: "BDFee"
						},
						width: "100px",
					},
					{
						title: "补单金额",
						dataIndex: "supplementAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
						width: "100px",
					},
				],
				riskStatusArr:[
					{
						id:"103321",
						name:"未风控"
					},{
						id:"103310",
						name:"已被风控"
					}
				],
				visibleFk: false,
				visiblehk: false,
				payInfo: "",
				taskDetail: null,
				payPwd: "",

				btnLoading: false,
				accountInfo: null,
				loading: false,
				userInfo: null,
				userEmail: "",
				roleInfo: "",
				bankInfo: {},
				riskStatus: {
					103301: "还未开始处理",
					103310: "已被风控",
					103311: "已被风控：董监高员工校验失败",
					103312: "已被风控：超单人月累计金额限制",
					103313: "已被风控：超单人连续12月累计金额限制",
					103314: "已被风控：月交易超过限额",
					103321: "未被风控",
				},
				appealModal: true,
				BDModal: false,
				recordTradeId: "",
				BJAmount: 0,
				payEnterpriseName: "",

				verifyCode: "", //验证码
				codeName: "获取验证码",
				snsMsgWait: 59,
				disable: false,
				configValue: "100401",
				verifyCodeCounter: "",
				enterpirseBindPhone: "",
				visibleAlipay: false,
				enterpriseRealName: "",
				alipayRechargeIpt: "",
				loadingFail: false,
				detailList:[],
				voucherModel:false,
				detailVoucherList:[],
				detailResultList:[]
			};
		},
		created() {
			this.userInfo = Vue.ls.get("Login_Userinfo");
			this.payInfo = JSON.parse(window.localStorage.getItem("taskInfo"));
			this.getRole();
		},
		mounted() {
			this.enterpriseRealName = Vue.ls.get("Login_Userinfo").realname;
			this.getPayDetail();
			this.getBanknum();
			this.getPayConfig();
			this.getVerifyType();
			this.getEnterpriseBindPhone();
			this.getPayResultInfo();
		},
		methods: {
			handleUploadModalOk() {
				this.examinForm.validateFields((err, fieldsValue) => {
					if (err) {
						return;
					}
					if (this.fileList.length === 0) {
						this.$message.error("请上传任务结果！");
						return;
					}
					let isUpload = true;
					this.fileList.forEach((item) => {
						if (item.fileName === undefined) {
							item.status = "error";
							isUpload = false;
						}
					});
					if (!isUpload) {
						this.$message.error("上传文件中有错误文件，请删除后提交");
						return;
					}
					this.confirmLoadingUpload = true;
					const description = this.examinForm.getFieldsValue();
						let uploadParams = {
							payId: this.payInfo.id,
							resultItems: this.fileList,
							content: description.content
						};
						// console.log('批量上传',params);
						// return
						resultSupplementUpload(Object.assign(uploadParams)).then((res) => {
							this.confirmLoadingUpload = false;
							if (res.success) {
								this.$message.success(res.message);
								this.fileList = [];
								this.visibleExamineModal = false;
								this.getPayResultInfo();
							} else {
								this.$message.error(res.message);
							}
						});
				});
			},
			beforeUpload(file) {
			  const isJpgOrPng = true;
			  if (!isJpgOrPng) {
			    this.$message.error("上传照片格式不正确!");
			    return false;
			  }
			  const isLt2M = file.size / 1024 / 1024 < 50;
			  if (!isLt2M) {
			    this.$message.error("上传文件大小不能超过50MB!");
			    return false;
			  }
			  return isJpgOrPng && isLt2M;
			},
			handleChange(info) {
			  let fileList = [...info.fileList];
			  // 1. Limit the number of uploaded files
			  fileList = fileList.slice(-9);
			  // 2. read from response and show file link
			  fileList = fileList.map((file) => {
			    if (file.response) {
			      // Component will show file.url as link
			      file.url = "https://oss.zfycloud.com" + "/" + file.response.result;
			      file.fileUrl =
			        "https://oss.zfycloud.com" + "/" + file.response.result;
			      file.fileName = file.name;
			    }
			    return file;
			  });
			  this.fileList = fileList;
			},
			getPayResultInfo(){
				getPayResultByPayId(this.payInfo.id).then(res => {
					if(res.result){
						this.detailResultList = res.result;
					}
				})
			},
			handleVoucherByDetail(val){
				this.detailVoucherList = [];
				getVoucherByDetail(val).then(res => {
					if(res.result){
						res.result.forEach((item,index) => {
							if(item && item != null){
								this.detailVoucherList.push(item);
							}
						})
					}
					if(this.detailVoucherList.length > 0){
						this.voucherModel = true;
					}else{
						this.$message.warning("当前付款单还未生成电子回单，请晚点再试");
					}
				})
			},
			
			//支付宝充值
			alipayRecharge() {
				if (!this.alipayRechargeIpt) {
					this.$message.info("请输入转入金额");
					return;
				}
				if (this.alipayRechargeIpt < 0 || this.alipayRechargeIpt == 0) {
					this.$message.info("转入金额需大于0");
					return;
				}

				alipayRecharge({
					transAmount: this.alipayRechargeIpt,
					tag: "2"
				}).then(
					(res) => {
						if (res.success) {
							this.alipayRechargeIpt = "";
							this.visibleAlipay = false;
							this.$confirm({
								icon: " ",
								title: "请选择支付结果",
								content: "如有其他问题请联系客户经理或拨打400-996-2055",
								okText: "付款成功",
								cancelText: "取消",
								onOk() {
									return new Promise((resolve, reject) => {
										window.location.reload();
										resolve();
									}).catch(() => console.log("Oops errors!"));
								},
							});
							window.open(res.result.url);
						}
					}
				);
			},
			telReg(tel) {
				let reg = /^(\d{3})\d{4}(\d{4})$/;
				return tel.replace(reg, "$1****$2");
			},
			//获取企业绑定号码
			getEnterpriseBindPhone() {
				getEnterpriseBindPhone().then((res) => {
					if (res.success && res.result) {
						this.enterpirseBindPhone = this.telReg(res.result.configValue);
					}
				});
			},
			// 60秒后重新获取验证码
			countdown() {
				this.verifyCodeCounter = setInterval(
					function() {
						this.codeName = this.snsMsgWait + "s后重新获取";
						this.snsMsgWait = this.snsMsgWait - 1;
						if (this.snsMsgWait < 0) {
							clearInterval(this.verifyCodeCounter);
							(this.codeName = "获取验证码"),
							(this.snsMsgWait = 59),
							(this.disable = false);
						}
					}.bind(this),
					1000
				);
			},

			//获取验证码
			getVerifyCode() {
				if (this.disable) {
					// this.$u.toast('操作太频繁，请稍后重试');
					return;
				}

				getPayChenkCode()
					.then((res) => {
						if (res) {
							this.countdown();
							this.disable = true;
							this.$message.success("验证码已发送");
						}
					})
					.catch((e) => {
						this.$message.error(res.message);
					});
			},
			//获取验证类型
			getVerifyType() {
				getEnterprisePayCheckMode().then((res) => {
					if (res.success && res.result) {
						this.configValue = res.result.configValue;
					} else {}
				});
			},
			//获取收款账号
			getPayConfig() {
				getEnterprisePayConfig("103101").then((res) => {
					if (res.success && res.result) {
						this.bankInfo = res.result;
					}
				});
			},
			DBDatas(parameter) {
				return queryEnterpriseTradeSupplementDetaileds(
					Object.assign({
						tradeDetaildId: this.recordTradeId
					}, parameter)
				).then((res) => {
					// res.result.data = res.result.records
					return res.result;
				});
			},
			BDDetail(record) {
				this.recordTradeId = record.id;
				this.BDModal = true;
			},
			infoAppeal() {
				const h = this.$createElement;
				this.$info({
					title: "风控提示",
					content: h("div", {}, [
						h("p", "已被风控，请联系您的业务经理或者拨打400-99-23023"),
					]),
					onOk() {},
				});
			},
			// 获取银行卡号
			getBanknum() {
				getBanknum().then((res) => {
					if (res.success && res.result) {
						// this.bankInfo = res.result
						this.payEnterpriseName = res.result.payEnterpriseName;
					}
				});
			},
			// 查询用户当前角色
			getRole() {
				getRoleType().then((res) => {
					// console.log(res)
					if (res.success) {
						this.roleInfo = res.result;
					}
				});
			},
			// 返回
			ret() {
				window.localStorage.setItem("submenu", "settlementManage");
				this.$router.push("/settlement");
			},
			// 获取任务详情
			getPayDetail() {
				getPayDetails({
					id: this.payInfo.id
				}).then((res) => {
					if (res.success) {
						this.taskDetail = res.result;
					}
				});
			},
			// 获取预付款列表getYfkList
			datas(parameter) {
				return getEnterpriseTradeDetaileds(
					Object.assign({
							tradeId: this.payInfo.orderMainId
						},
						parameter,
						this.params
					)
				).then((res) => {
					// res.result.data = res.result.records
					this.detailList = res.result;
					return res.result;
				});
			},
			// 查询
			handleToSearchEnterprise() {
				this.$refs.setTable.refresh(true);
			},
			// 重置
			resetSearchEnterprise() {
				this.params = {
					payeeName: "",
					riskStatus: "",
				};
				this.$refs.setTable.refresh(true);
			},
			//审核不通过
			shFail() {
				this.loadingFail = true;
				this.$confirm({
					title: "确定取消该付款单?",
					onOk: () => {
						return new Promise((resolve, reject) => {
							taskWorkerPay({
								payId: this.payInfo.id,
								status: false
							}).then((res) => {
									this.loadingFail = false;
									if (res.success) {
										this.$message.success(res.message);
										this.getPayDetail();
										resolve();
									} else {
										this.$message.error(res.message);
										reject();
									}
								}
							);
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel: () => {
						this.loadingFail = false;
					},
				});
			},
			// 审核通过
			Sh() {
				this.loading = true;
				let _title = '';
				getRiskDetailList(this.taskDetail.orderMainId).then((res) =>{
					if(res.success && res.result.length > 0){
						_title = '当前付款单中有：'+ res.result.length +' 笔订单已被风控,风控的订单复核后将从该付款单中移除,';
					}
					console.log(_title)
					this.$confirm({
						title:(h) => (
              <div>
                <span style="color:red;">
                  {_title}
                </span>
                是否确认审核通过？
              </div>
            ),
						onOk: () => {
							return new Promise((resolve, reject) => {
								taskWorkerPay({
									payId: this.payInfo.id,
									status: true
								}).then(
									(res2) => {
										this.loading = false;
										// console.log(res)
										if (res2.success) {
											this.$message.success(res2.message);
											this.getPayDetail();
											resolve();
										} else {
											this.$message.error(res2.message);
											reject();
										}
									}
								);
							}).catch(() => console.log("Oops errors!"));
						},
						onCancel: () => {
							this.loading = false;
						},
					});
				});
				
			},
			// 付款
			Fk() {
				if (
					!this.taskDetail.payWalletType ||
					this.taskDetail.payWalletType == "1"
				) {
					getBalance("bank").then((res) => {
						if (res.success) {
							this.accountInfo = res.result;
						}
					});
					getUserBindEmail().then((res) => {
						// console.log(res)
						if (res.success) {
							this.userEmail = res.message;
						}
					});
					this.visibleFk = true;
				}

				if (this.taskDetail.payWalletType == "2") {
					getBalance("alipay").then((res) => {
						if (res.success) {
							this.accountInfo = res.result;
						}
					});
					getUserBindEmail().then((res) => {
						// console.log(res)
						if (res.success) {
							this.userEmail = res.message;
						}
					});
					this.visibleFk = true;
				}
			},
			// 重置密码
			resetMm() {
				let that = this;
				this.$confirm({
					title: "确认重置密码？",
					content: "密码将发送至邮箱:" + that.userEmail,
					okText: "确认",
					cancelText: "取消",
					onOk() {
						return new Promise((resolve, reject) => {
							resetPwd().then((res) => {
								// console.log(res)
								if (res.success) {
									that.$message.success(res.message);
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
				});
			},
			// 付款信息
			handleCancelFk() {
				this.visibleFk = false;
				this.btnLoading = false;
				this.payPwd = "";
				this.verifyCode = "";
				this.codeName = "获取验证码";
				clearInterval(this.verifyCodeCounter);
			},
			// 请转入
			showHkxx() {
				if (
					!this.taskDetail.payWalletType ||
					this.taskDetail.payWalletType == "1"
				) {
					this.visiblehk = true;
				}
				if (this.taskDetail.payWalletType == "2") {
					this.visibleAlipay = true;
				}
			},
			// 立即付款
			ljfk() {
				// this.visibleFk = false
				this.btnLoading = true;
				if (this.payPwd === "" && this.configValue == "100401") {
					this.$message.error("支付密码不能为空");
					this.btnLoading = false;
					return;
				}
				if (this.payPwd === "" && this.configValue == "100403") {
					this.$message.error("支付密码不能为空");
					this.btnLoading = false;
					return;
				}
				if (this.verifyCode === "" && this.configValue == "100402") {
					this.$message.error("请输入验证码");
					this.btnLoading = false;
					return;
				}
				if (this.verifyCode === "" && this.configValue == "100403") {
					this.$message.error("请输入验证码");
					this.btnLoading = false;
					return;
				}
				prePay({
					payId: this.payInfo.id
				}).then((res) => {
					if (res.success) {
						if (res.result.useAllBalance === 1) {
							taskPay({
								payId: this.payInfo.id,
								prePayId: res.result.prePayId,
								pwd: this.payPwd,
								ver: this.verifyCode,
							}).then((result) => {
								this.btnLoading = false;
								if (result.success) {
									this.getPayDetail();
									this.handleCancelFk();
									this.$message.success(result.message);
								} else {
									this.$message.error(result.message);
								}
							});
						} else {
							this.btnLoading = false;
							this.$message.error("余额不足，请充值！");
						}
					}
				});
				// if (this.accountInfo.normalBalanceAmount<(this.taskDetail.payAmount+this.taskDetail.payFeeAmount)) {
				//   this.$message.error('余额不足，请充值！')
				//   this.btnLoading = false
				// } else {
				//   if (this.payPwd === '') {
				//     this.$message.error('支付密码不能为空')
				//     this.btnLoading = false
				//     return
				//   }
				//   prePay({payId:this.payInfo.id}).then(res => {
				//     if (res.success) {
				//       taskPay({payId:this.payInfo.id,prePayId:res.result.prePayId,pwd:this.payPwd}).then(result => {
				//         this.btnLoading = false
				//         if (result.success) {
				//           this.getPayDetail()
				//           this.handleCancelFk()
				//           this.$message.success(result.message)
				//         } else {
				//           this.$message.error(result.message)
				//         }
				//       })
				//     }
				//   })
				// }
			},
			// 汇款信息
			handleCancelhk() {
				this.visiblehk = false;
			},
			handleOkhk() {
				this.visiblehk = false;
			},
		},
	};
</script>
<style scoped lang="less">
	.ant-table-body {
		//样式穿透
		overflow-x: auto !important;
	}

	.content_tab {
		// width: 1507px;
		// width: 100%;
		// max-width:1300px;
		// display: inline-block;
		// width: 1200px;
	}

	@media screen and (max-width: 1400px) {
		.content_tab {
			// width: 1000px;
		}
	}

	.container_settlement {
		width: 100%;
		// max-width: 1600px;
	}

	.header-box {
		color: #000000;
		font-size: 14px;
		margin-bottom: 20px;
		width: 100%;

		.ret {
			color: rgba(0, 0, 0, 0.65);
			cursor: pointer;
		}
	}

	.header-one {
		width: 100%;
		box-sizing: border-box;
		padding: 27px 24px;
		border: 1px solid #f0f0f0;
	}

	.rask-title {
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.rt-right {
			// width: 100px;
			min-width: 100px;
		}

		.rt-left {
			display: flex;
			align-items: flex-end;

			.rt-left-title {
				font-size: 20px;
				color: rgba(0, 0, 0, 0.85);
				margin-right: 16px;
			}

			.rt-left-time {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}

	.rask-content {
		font-size: 14px;
		width: 100%;
		color: rgba(0, 0, 0, 0.85);

		.rc-mgb {
			margin-top: 24px;

			span {
				color: #1890ff;
			}
		}
	}

	.search {
		width: 100%;
		padding: 32px 0 0 0;
		display: flex;
		align-items: center;
	}

	.table-search-submitButtons {
		display: flex;
		align-items: center;
		height: 40px;
		margin-left: 30px;
	}

	.fkxx {
		list-style: none;
		padding: 0;

		.li_flex {
			display: flex;
			align-items: center;
		}

		li {
			.bind_phone {
				padding-left: 84px;
			}

			margin: 30px;
			color: rgba(51, 51, 51, 0.85);
			font-size: 14px;

			.left {
				// width: 60px;
				margin-right: 24px;
			}

			.right {
				display: flex;
				align-items: center;
			}
		}
	}

	.hkxx-header {
		color: #333333;
		font-size: 14px;
		margin-bottom: 24px;
		line-height: 24px;
	}

	.hkxx-content {
		list-style: none;
		padding: 0;
		border-bottom: 1px solid #f0f0f0;
		border-right: 1px solid #f0f0f0;

		li {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #000000;

			.left {
				width: 200px;
				height: 56px;
				padding: 19px 24px;
				border-top: 1px solid #f0f0f0;
				border-left: 1px solid #f0f0f0;
			}

			.right {
				width: 466px;
				height: 56px;
				padding: 19px 24px;
				border-top: 1px solid #f0f0f0;
				border-left: 1px solid #f0f0f0;
				color: rgba(0, 0, 0, 0.85);
			}
		}
	}

	.pop-content {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 0 16px 0;
		border-bottom: 1px solid #e1e4e8;

		.img {
			width: 60px;
			height: 60px;
			margin-right: 10px;
			border-radius: 50%;
		}

		.person {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.85);
			margin-bottom: 10px;

			span {
				&:last-child {
					font-size: 12px;
					color: rgba(0, 0, 0, 0.65);
					margin-left: 10px;
				}
			}
		}

		.construction {
			width: 230px;
			text-align: justify;
			font-size: 14px;
		}
	}

	.tags {
		padding-top: 10px;
	}

	.img_tips {
		display: flex;
	}

	.alipay_recharge {
		display: flex;
		align-items: center;
		padding-top: 30px;

		span {
			width: 75px;
		}
	}
</style>
import { getAction,deleteAction,putAction,postAction} from '@/api/manage';
import { axios } from '@/utils/request';

//查询付款单风控订单
const getRiskDetailList = (params) => getAction('/api/tax/client/enterprise/trade/getRiskDetailList/'+ params);
//根据付款明细查询电子回单
const getVoucherByDetail = (params) => getAction('/api/tax/wallet/payLog/voucher/detail/'+ params);
//根据付款单编号查询电子回单
const getVoucherByTrade = (params) => getAction('/api/tax/wallet/payLog/voucher/trade/'+ params);
//获取付款单任务成果
const getPayResultByPayId = (payId) => getAction('/slash-youth/client/b/taskWorkerResult/getResult/'+payId);
//补传任务成果
const resultSupplementUpload = (params) => postAction('/slash-youth/client/b/taskWorkerResult/resultSupplementUpload',params);

export {
	getRiskDetailList,
	getVoucherByDetail,
	getVoucherByTrade,
	getPayResultByPayId,
	resultSupplementUpload,
}